.food-menu-main {
  padding-bottom: 75px;
  .resturant-info {
    border-style: dashed !important;
    box-shadow: 0px 30px 60px rgba($color-dark, 0.1);
    border-radius: 30px;
  }
  .accordion-button:not(.collapsed) {
    background-color: rgba($color-primary, 0.9);
    color: $color-white;
    h4{
      color:$color-white;
    }
    &:after{
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
  }
  .food-list {
    .accordion-item {
      background-color: $color-light-2;
      border-radius: 30px !important;
      overflow: hidden;
      margin-bottom: 10px;
      .food-items-list {
        padding:  5px;
        .food-item {
          padding:10px;
          box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
          border-radius: 30px;
          background-color: $color-white;

          & + .food-item {
            margin-top: 10px;
          }
          .food-item-img {
            position: relative;
            margin-right: 0px;
            flex: 0 0 120px;
            width: 120px;
            margin-right: 20px;
            line-height: 0;
            
            .food-img {
              height: 100px;
              width: 120px;
              border-radius: 20px;
              object-fit: cover;
              object-position: center;
              
            }
          }
          .food-info {
            .food-type {
              flex: 0 0 18px;
              width: 18px;
              height: 18px;
              line-height: 16px;
            }
          }
          .customise-text{
            width: 120px;
            text-align: center;
          }
        }
      }
    }
  }
}
.addon-modal {
  max-height: 75vh;
  top: 25vh;

  .modal-content {
    width: 100%;
    max-width: 100%;
    padding-bottom: 100px;
    .modal-header {
      position: relative;
      display: block;
      .btn-close {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
    .modal-body {
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      .addon-list {
        .add-on-box {
          .add-on-choices {
            background-color: $color-white;
            padding: 15px;
            border-radius: 20px;
            margin: 15px 0;
            box-shadow: 0px 5px 15px rgba($color-dark, 0.2);
            .add-on-choice {
              display: flex;
              align-items: center;
              .food-type {
                margin-right: 10px;
                height: 15px;
                width: 15px;
                text-align: center;
                line-height: 12px;
                border-radius: 3px;
                background-color: $color-white;
                .svg-inline--fa {
                  font-size: 8px;
                }
              }
              .choice-title {
                font-weight: 500;
                margin-bottom: 0;
              }
              .add-on-price {
                margin-left: auto;
                margin-right: 10px;
                font-weight: 600;
                color: $color-primary;
              }
              & + .add-on-choice {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: $color-white;
    }
  }
  .view-cart-action {
    box-shadow: 0px 5px 15px rgba($color-dark, 0.2);

  }
}
