// common inputs ======
.form-group {
  label {
    font-weight: 600;
    color: rgba($color-dark, 0.4);
    margin-bottom: 10px;
  }
  .form-control {
    background-color: $color-light-2;
    border-color: $color-light-2;
    color: $color-dark;
    border-radius: 0;
    padding: 15px 20px;
    font-weight: 600;
    border-radius: 25px;
    height: auto !important;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}

/* ================== placeholder color ==============  */

.form-control::-webkit-input-placeholder,
::-webkit-input-placeholder {
  /* Edge */
  color: $color-dark;
}

.form-control:-ms-input-placeholder,
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $color-dark;
}

.form-control::placeholder,
::placeholder {
  color: $color-dark;
}

.form-control::-moz-placeholder {
  color: $color-dark;
  opacity: 1;
}

// ===================== custom inputs ===============
// default violet
.form-check-input {
  width: 1.4em;
  height: 1.4em;
  &.big {
    width: 1.8em;
    height: 1.8em;
  }
  &:checked {
    background-color: $color-primary;
    border-color: $color-primary;
  }
  &:focus{
    box-shadow: none;
  }
}

.form-switch {
  .form-check-input {
    width: 50px;
    height: 25px;
    margin-top: 0;
    margin-right: 10px;
    &:checked {
      background-color: $color-primary;
      border-color: $color-primary;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
    }
    &:checked:focus {
      background-color: $color-primary;
      border-color: $color-primary;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
    }
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $color-primary;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
    }
  }
  &.switch-green {
    .form-check-input {
      width: 50px;
      height: 25px;
      margin-top: 0;
      margin-right: 10px;
      &:checked {
        background-color: $color-success;
        border-color: $color-success;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
      }
      &:checked:focus {
        background-color: $color-success;
        border-color: $color-success;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
      }
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: $color-success;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
      }
    }
  }
}
