.restaurant-page {
  header {
    + div {
      margin-top: 52px !important;
    }
  }
  .restaurant-cover-img {
    height: 200px;
    position: relative;
    .cover-image {
      height: 200px;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color-dark, 0.2);
    }
    .discount-tag{
      padding:5px 10px ;
      position: absolute;
      top: 0;
      left: -5px;
      border-radius: 5px;
      font-weight: 600;

    }
    .rating{
      bottom:20px;
      position: absolute;
      padding:5px 10px ;
      right: 5px;
      border-radius: 10px;
      font-weight: 600;
    }
  }
  .restaurant-details-info{
    margin-top: -20px;
    position: relative;
    .restaurant-overview{
      background-color: $color-white;
    }
    .reserve-table{
      img{
        max-width: 50px;
        margin-right: 20px;
      }
    }
    .view-menu{
      img{
        max-width: 50px;
        margin-right: 20px;
      }
    }
  }
}
