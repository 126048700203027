.packages-information-wrapper {
  .display-image {
    .img-box {
      position: relative;
      .btn-view-all-images {
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
        img {
          max-width: 35px;
        }
      }
    }
  }
}
.customize-package-wrapper {
  .step {
    .form-check-label {
      flex-grow: 1;
    }
    .form-check-input {
      flex: 0 0 1.4em;
    }
    .food-img {
      flex: 0 0 50px;
      margin-right: 10px;
      img {
        height: 40px;
        width: 50px;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba($color-dark, 0.1);
      }
    }
  }
}

.packages-booking-details {
  .food-img {
    flex: 0 0 60px;
    margin-right: 15px;
    img {
      width: 60px;
      height: 50px;
      object-fit: cover;
      object-position: center;
      border-radius: 5px;
    }
  }
}

.decoration-modal {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  background-color: rgba($color-dark, 0.3);
  bottom: -100%;
  transition: all 300ms ease-in;
  &.active {
    bottom: 0;
  }
  .inner {
    padding: 50px 20px 20px;
    width: 100%;
    flex: 0 0 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    text-align: center;
    position: relative;
    .close-btn {
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      width: 40px;
      height: 40px;
      background-color: $color-primary;
      border-radius: 50%;
      background-size: 80% 80%;
    }
    .carousel-control-next {
      right: -20px;
    }
    .carousel-control-prev {
      left: -20px;
    }

    .carousel-indicators {
      [data-bs-target] {
        background-color: $color-white;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        opacity: 1;
      }
      bottom: -10px;
      .active {
        background-color: $color-primary;
      }
    }
    img {
      max-height: 225px;
    }
  }
}
