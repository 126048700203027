html {
  scroll-behavior: smooth;
  font-size: $base-font-size;
}
body {
  font-family:$font-family-inter;
  color: rgba($color-base, 0.5);
  line-height: $base-line-height;
  background-color: $color-light;
  font-size: $base-font-size;
}
*,
*:focus {
  outline: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
ul {
  list-style: none;
  padding-left: 0;
}
a {
  text-decoration: none !important;
  color: $color-secondary;
}
a:hover {
  color: $color-primary;
}
p {
  margin-bottom: 10px;
}
img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}
.app {
  min-height: 100vh;
}
/*****************************/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-family-inter;
  line-height: 1.2;
  font-weight: 600 !important;
  color: $color-primary;
}
h1,
.h1 {
  font-size: $font-heading-1; // 48px
}
h2,
.h2 {
  font-size: $font-heading-2; //30px
}
h3,
.h3 {
  font-size: $font-heading-3; // 26px
}
h4,
.h4 {
  font-size: $font-heading-4; // 20px
}
h5,
.h5 {
  font-size: $font-heading-5; //16 px
}
h6,
.h6 {
  font-size: $font-heading-6; // 14px
}
/******* Helpers ********************************************************************/
/*-----  fonts ---------- */
.font-inter {
  font-family: $font-poppins !important;
}

.font-inter {
  font-family: $font-poppins !important;
}

/* font sizes ======= */
.font-smallest {
  font-size: $font-smallest !important; //12px
}
.font-small {
  font-size: $font-small !important; // 13px
}
.font-large {
  font-size: $font-large !important;
}
.font-medium{
  font-size: $font-medium !important;
}

.font-largest {
  font-size: $font-largest !important;
}

.text-primary,
a.text-primary:hover,
a.text-primary:focus {
  color: $color-primary !important;
}
.text-secondary,
a.text-secondary:hover,
a.text-secondary:focus {
  color: $color-secondary !important;
}

.text-success,
a.text-success:hover,
a.text-success:focus {
  color: $color-success !important;
}
.text-danger,
a.text-danger:hover,
a.text-danger:focus {
  color: $color-danger !important;
}

.text-muted,
a.text-muted:hover,
a.text-muted:focus {
  color: $color-muted !important;
}

/* backgrounds ==== */
.bg-light {
  background-color: $color-light !important;
}
.bg-light-2 {
  background-color: $color-light-2 !important;
}
.bg-primary {
  background-color: $color-primary !important;
}
.bg-secondary {
  background-color: $color-secondary !important;
}

.bg-dark {
  background-color: $color-dark !important;
}
.bg-success {
  background-color: $color-success !important;
}
.bg-danger {
  background-color: $color-danger !important;
}
.bg-info {
  background-color: $color-info !important;
}
.bg-grey {
  background-color: $color-grey !important;
}

// bg transparent
.bg-transparent-dark {
  background-color: rgba($color-dark, 0.5) !important;
}
/*************Font Weight****************/
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-semi {
  font-weight: 600 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: 800 !important;
}
// =============================
.container {
  max-width: 1470px;
}
.text-underline {
  text-decoration: underline !important;
}
//==========
.line-height-0 {
  line-height: 0;
}
.line-height-1 {
  line-height: 1;
}
.line-height-2 {
  line-height: 2;
}
//=========borders

// opacities ==============================
.opacity-one-fourth {
  opacity: 0.25;
}
.opacity-half {
  opacity: 0.5;
}
.opacity-three-fourth {
  opacity: 0.75;
}
.opacity-full {
  opacity: 1;
}
select.remove-default-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
//spacing classes

.mlr-10 {
  margin: 0px 10px;
}

// max width classes

.mw-25 {
  max-width: 25%;
}
.mw-50 {
  max-width: 50%;
}
.mw-75 {
  max-width: 75%;
}

//====== image-fit=====
.img-fit {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.cursor-pointer {
  cursor: pointer;
}

// border-colors=-===={
.border-dark {
  border-color: #000000 !important;
}

.border {
  &.border-primary {
    border-color: $color-primary !important;
  }
  &.border-success {
    border-color: $color-success !important;
  }
  &.border-danger {
    border-color: $color-danger !important;
  }
}

.text-deleted {
  text-decoration: line-through;
}

.rounded {
  border-radius: 10px !important;
}
.rounded-lg {
  border-radius: 30px !important;
}

.close {
  font-size: 20px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px !important;
  margin-right: 0px !important;
  position: relative;
  z-index: 9;
}

.border-thick {
  border-width: 2px !important;
}

.no-arrow.dropdown-toggle:after {
  content: none;
}

.common-container {
  max-width: 767px;
  // padding:0 15px;
  margin: 0 auto;
}
.accordion-button:focus {
  outline: none;
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  background-color: rgba($color-primary, 0.9);
  color: $color-white !important;
  &:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
}
.shadow-lg {
  box-shadow: 0px 30px 60px rgba($color-dark, 0.1);
}
// =======================media querries=============================
