.order-details-main {
  padding-bottom: 75px;
  .order-success-info {
    background-color: $color-white;
    box-shadow: 0px 30px 60px rgba($color-dark, 0.1);
  }
  .accordion-item {
    background-color: $color-light-2;
    border-radius: 30px;
    overflow: hidden;
    border: 0 !important;
    box-shadow: 0px -0px 15px rgba($color-primary, 0.2);
    .accordion-button {
      font-size: $font-small;
    }
    .timeline {
      padding-top: 0;
      padding-bottom: 30px;
    }
  }

  .ordered-items-list {
    box-shadow: 0px 30px 60px rgba($color-dark, 0.1);
    padding: 20px 10px;
    .ordered-item {
      .item-name {
        flex: 0 0 32%;
        max-width: 32%;
      }
      .item-quantity {
        flex: 0 0 25%;
        max-width: 25%;
      }
      .item-price {
        flex: 0 0 20%;
        max-width: 20%;
      }
      .item-total-price {
        flex: 0 0 23%;
        max-width: 23%;
        text-align: right;
      }
      & + .ordered-item {
        margin-top: 5px;
      }
      &:not(.heading) {
        font-size: $font-small;
      }
    }
  }
}

.user-orders-main {
  padding-bottom: 50px;
  .orders-list {
    &.current-order-list{
      .order{
        padding-bottom: 30px;
      }
    }
    .order {
      padding: 15px;
      padding-bottom: 0;
      & + .order {
        margin-top: 15px;
      }
    }
  }
}

.timeline {
  padding: 20px 0;
  position: relative;
  min-height: 80px;
  z-index: 1;
}

.timeline li {
  list-style: none;
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
  font-size: $font-smallest;
  // white-space: nowrap;
}

// ul:nth-child(1){
//   color: $color-success;
// }

.timeline li:before {
  content: "";
  width: 25px;
  height: 25px;
  border: 2px solid $color-warning;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 50px;
  margin: 0 auto 10px auto;
  background: $color-white;
  color: #000;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.timeline li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: grey;
  top: 12px;
  left: -50%;
  z-index: -999;
  transition: all ease-in-out 0.3s;
}

.timeline li:first-child:after {
  content: none;
}
.timeline li.active-tl {
  color: $color-dark;
  font-weight: 600;
}
.timeline li.active-tl:before {
  background: $color-success;
  color: #f1f1f1;
  border: 2px solid $color-success;
}

.timeline li.active-tl + li:after {
  background: $color-success;
}

.table-booking-success {
  .accordion {
    .accordion-button:not(.collapsed) {
      color: $color-base;
    }
    .accordion-body {
      padding: 10px;
    }
  }
}
