

/*=============================
            functions
  ===========================*/

// margins and paddings 
$spaceamounts: (6,
10,
15,
20,
25,
30,
35,
40,
45,
50,
75,
100); // Adjust this to include the pixel amounts you need.
$sides: (top,
bottom,
left,
right); // Leave this variable alone
@each $space in $spaceamounts {
    @each $side in $sides {
        .m-#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px !important;
        }
        .p-#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px !important;
        }
    }
}