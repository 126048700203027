.table-reservation-page {
  .accordion-flush {
    .accordion-item {
      border-bottom: 0;
      .table-box {
        background-color: rgba($color-primary, 0.5);
        border-radius: 10px;
        color: $color-white;
        padding: 10px;
        margin-bottom: 10px;
        .table-no {
          margin-bottom: 10px;
        }
        .capacity,
        .availablity {
          margin-bottom: 0;
        }
        &.available-table {
          background-color: $color-success;
        }
        &.occupied-table {
          background-color: $color-danger;
        }
      }
    }
  }
  .accordion-button {
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
}
