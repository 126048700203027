header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  padding: 5px 15px;
  z-index: 999;
  background-color: $color-light;

  .btn-menu-toggle {
    font-size: $font-heading-5;
  }

  .header-actions {
    .btn-indicator {
      position: relative;
      padding: 5px;
      font-size: $font-heading-3;
      color: $color-muted;
      margin-left: 10px;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
      .count-indicator {
        font-size: $font-smallest;
        font-weight: 500;
        height: 15px;
        width: 15px;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        line-height: 15px;
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }

    .dropdown-menu {
      min-width: 320px;
      border: 0;
      box-shadow: 0 0 5px rgba($color-dark, 0.2);
      padding: 0;
      border-radius: 0;
    }
    .header-messages {
      .messages-list {
        max-height: 400px;
        overflow-y: auto;
        .message-box {
          padding: 10px;
          cursor: pointer;
          .user-img {
            max-width: 25px;
            margin-right: 10px;
            img {
              height: 25px;
              width: 25px;
              border-radius: 50%;
              object-fit: cover;
              object-position: center;
            }
          }
          p {
            margin-bottom: 0;
          }
          & + .message-box {
            border-top: 1px solid rgba($color-dark, 0.2);
          }
        }
      }
    }

    .header-notifications {
      .notifications-list {
        max-height: 400px;
        overflow-y: auto;
        .notification-box {
          padding: 10px;
          cursor: pointer;
          .icon {
            max-width: 35px;
            margin-right: 10px;
            background-color: rgba($color-secondary, 0.5);
            border-radius: 50%;
            height: 35px;
            padding: 10px;
            img {
              max-width: 100%;
            }
          }
          p {
            margin-bottom: 0;
            line-height: 1.4;
          }
          & + .notification-box {
            border-top: 1px solid rgba($color-dark, 0.2);
          }
        }
      }
    }

    .header-user {
      margin-left: 10px;
      .btn {
        padding: 0;
      }
      .user-img {
        max-width: 30px;
        height: 30px;
        img {
          // height: 40px;
          // width: 40px;
          // object-fit: cover;
          // object-position: center;
          // box-shadow: 0 0 20px 0 rgba($color-dark, 0.2);
          // border: 1px solid rgba($color-primary, 0.8);
          // border-radius: 50%;
          height: 25px;
          width: 25px;
          object-fit: cover;
          object-position: center;
          // box-shadow: 0 0 20px 0 rgba($color-dark, 0.2);
          border: 1px solid rgba($color-dark, 0.8);
          border-radius: 50%;
        }
      }
      .dropdown-menu {
        min-width: 200px;
        border: 0;
        box-shadow: 0 0 10px rgba($color-dark, 0.5);
        border-radius: 0;

        padding: 10px;
        a {
          display: block;
          padding: 5px 10px;
          border-radius: 10px;

          &:hover {
            background: rgba($color-primary, 1);
            color: $color-primary;
          }
        }
        .icon {
          min-width: 30px;
          display: inline-block;
          font-size: $font-heading-5;
        }
        h4 {
          .rest-icon {
            max-width: 40px;
            margin-right: 10px;
            font-size: $font-medium;
          }

          padding: 5px;
          margin-bottom: 10px;
        }
      }
    }
  }
  + div {
    margin-top: 60px !important;
  }

  .primary-menu-wrapper {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba($color-dark, 0.5);
    z-index: 99999;
    transition: all 300ms ease;
    &.active{
      left: 0;
    }
    .inner {
      height: 100%;
      max-width: 340px;
      padding: 0;
      position: relative;
      .btn-menu-close {
        position: absolute;
        right: 10px;
        background-color: transparent;
        border: 0;
        top: 5px;
        z-index: 2;
      }
      .top {
        .logo {
          img {
            max-width: 100px;
          }
        }
      }
      .primary-menu {
        margin-top: 50px;
        li {
          button {
            display: block;
            width: 100%;
            text-align: left;
            border: 0;
            background-color: transparent;
            padding: 10px;
          }
        }
      }
    }
  }
}
