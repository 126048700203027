@import "../abstract/variable";
.common-login {
  .inner{
    min-height: 100vh;
  }
  .top {
    text-align: center;
    padding: 30px 20px 80px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0px 4px 30px rgba($color-base, 0.06);
    background-color: $color-white;
    position: relative;
    .title {
      position: absolute;
      bottom: 0;
      left: 30px;
      border-bottom: 2px solid $color-primary;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
    img {
      max-height: 200px;
      margin: 0 auto;
    }
  }
  .bottom {
    padding: 50px 20px 30px;
    .form-group {
      .form-control {
        border-bottom: 0;
        border-radius: 0;
        background-color: transparent;
        border-color: transparent;
        border-bottom: 1px solid $color-dark;
        padding: 10px 0;
      }
    }
  }
}
