.profile-main {
  .profile-picture {
    position: relative;
    height: 75px;
    width: 100%;
    margin: 0 auto;
    max-width: 75px;
    display: block;
    border: 1px solid;
    border-radius: 50%;
    span {
      display: inline-block;
    }
    .img-box {
      border-radius: 50%;
      height: 75px;
      width: 75px;
      object-fit: cover;
      object-position: center;
   
    }
    .edit-icon {
      position: absolute;
      bottom: -2px;
      right: -2px;
      box-shadow: 0 0 5px rgba($color-dark, 0.5);
      background-color: $color-white;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      text-align: center;
      line-height: 25px;
    }
  }
}
