.cart-main {
  .cart-inner {
    padding-bottom: 75px;
    .cart-items-list {
      background-color: $color-light-2;
      padding:20px 10px;
      border-radius: 20px;
      .cart-item {
        background-color: $color-white;
        padding: 10px;
        border-radius: 15px;
        box-shadow: 0px 10px 40px rgba($color-base, 0.03);
        .top {
          margin-bottom: 10px;
          .left {
            
            .food-type {
              margin-right: 10px;
              width: 17px;
              height: 17px;
              text-align: center;
              line-height: 16px;
            }
          }
          .quantity {
            margin-left: auto;
            margin-right: 0px;
          }
        }
        .bottom {
          font-weight: 500;
          color: $color-primary;
        }
        & + .cart-item {
          margin-top: 10px;
        }
      }
    }
  }
  .grand-total,
  .apply-code {
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
  }
}
