.restuarant-list {
  padding-top: 10px;
  .restaurant-box {
    .restaurant-img {
      border-radius: 10px;
      box-shadow: 0 0 5px rgba($color-dark, 0.1);
      overflow: hidden;
      line-height: 0;
      margin-bottom: 10px;
      position: relative;
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-dark, 0.2);
      }
      .discount-tag{
        padding:5px 10px ;
        position: absolute;
        top: 0;
        left: -5px;
        border-radius: 5px;
        font-weight: 600;
        z-index: 2;
      }
      .rating{
        bottom:5px;
        position: absolute;
        padding:5px 10px ;
        right: 5px;
        border-radius: 5px;
        z-index: 2;
        font-weight: 600;
        margin-bottom: 0;
      }
      img {
        height: 135px;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
