.welcome-screen {
  min-height: 100vh;
  padding: 30px 20px 10px;
  .company-logo {
    margin-bottom: 20px;
    img {
      max-width: 70px;
    }
  }
  h1 {
    font-weight: 900;
    margin-bottom: 10px;
  }
  .bottom-content {
    position: relative;
    text-align: center;
    .btn {
      position: absolute;
      bottom: 30px;
      left: 50%;
      width: 100%;
      max-width: 275px;
      transform: translateX(-50%);
    }
  }
}
