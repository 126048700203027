/*=============================
            Variables
  ===========================*/

/***** colors**********/

/*=========fonts ======*/
$color-base: #000000;
$color-primary: #87449A;
$color-secondary: #2f4cdd;
$color-light: #EDEDED;
$color-light-2:#F9F9F9;
$color-white: #ffffff;
$color-muted: #8c8d91;
$color-dark: #393939;
// hover colors
$color-primary-light: rgba(44, 51, 51, 0.3);
$color-secondary-light: rgba(52, 56, 205, 0.3);
$color-success: #98dd44;
$color-grey: #e3eaef;
$color-info: #4a74e9;
$color-danger: #f54747;
$color-warning: #fd7e14;

// fonts
$base-font-size: 16px;
$font-smallest: calc(1rem - 5px); //10px
$font-small: calc(1rem - 3px); //12px
$font-medium: calc(1rem - 2px); //16px
$font-heading-6: calc(1rem - 2px); // 18px
$font-heading-5: calc(1rem); // 20px
$font-heading-4: calc(1rem + 2px); // 24px
$font-heading-3: calc(1rem + 6px); // 30px
$font-heading-2: calc(1rem + 10px); // 36px
$font-heading-1: calc(2rem); // 48px
$font-large: calc(4rem); // 60px;
$font-largest: calc(5rem - 3px); // 72px;
/*=========fonts families======*/

$font-family-inter: "Inter", sans-serif;
$font-poppins: "Poppins", sans-serif;

/*=========Line Height======*/

$base-line-height: 18px;
$line-height-14: 14px;

/*=========letter spacing======*/

$letter-spacing-1: 1px;
$letter-spacing-2: 1px;
$letter-spacing-3: 1px;
