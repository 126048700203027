.search-main{
    position: fixed;
    top:-100%;
    // display: none;
    left: 0;
    width: 100%;
    background-color: $color-white;
    z-index: 99;
    transition: all 300ms ease;
    &.active{
        top: 0;
        // display: block;
    }
}