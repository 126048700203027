.reservation-timing-page {
  .available-timing-list {
    margin: 0 -10px;
    li {
      display: inline-block;
      padding: 10px;
      flex: 0 0 33.33%;
      max-width: 33.33%;
      label {
        width: 100%;
        position: relative;
        button {
          width: 100%;
          border-radius: 5px;
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
        input:checked ~ button {
          background-color: $color-primary !important;
          border-color: $color-primary !important;
        }
      }
    }
  }
}
